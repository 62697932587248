import React from 'react';

// import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';

import HomePage from './components/HomePage';
import BingBox from './components/Bing/BingBox';
import DalleBox from './components/Dalle/DalleBox';

const App: React.FC = () => {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/bingbox/:pageNumber?" element={<BingBox />} />
                <Route path="/dallebox/:pageNumber?" element={<DalleBox />} />
            </Routes>
        </Router>
    );
};

export default App;

// ReactDOM.render(<App />, document.getElementById('root'));