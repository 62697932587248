import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
    const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // 初始背景色
    const [menuOpen, setMenuOpen] = useState(false); // 選單的開關狀態

    useEffect(() => {
        document.title = 'AI Image Galleries Home';
        document.body.style.backgroundColor = backgroundColor; // 設置 body 背景色
        document.documentElement.style.backgroundColor = backgroundColor; // 設置 html 背景色
    }, [backgroundColor]); // 當背景色改變時更新

    // 可選的背景色列表
    const backgroundColors = [
        { name: 'Default (White)', value: '#ffffff' },
        { name: 'Soft Gray', value: '#f0f0f0' },
        { name: 'Light Blue Gray', value: '#e8eaf6' },
        { name: 'Soft Blue White', value: '#f7f9fc' },
        { name: 'White Gray', value: '#fafafa' },
        { name: 'Soft Green', value: '#f9fbe7' },
        { name: 'Dark Gray', value: '#1e1e1e' },
        { name: 'Deep Blue', value: '#303f9f' },
    ];

    // 改變背景色
    const handleBackgroundChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setBackgroundColor(event.target.value);
    };

    // 切換選單顯示
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="homepage-container">
            {/* 可隱藏選單 */}
            <nav className="navbar">
                <div className="navbar-header">
                    <h1 className="navbar-title">AI Gallery</h1>
                    <button onClick={toggleMenu} className="navbar-toggle-btn">
                        {menuOpen ? 'Close Menu' : 'Open Menu'}
                    </button>
                </div>
                {menuOpen && (
                    <div className="navbar-menu">
                        <label htmlFor="background-select">Choose Background Color: </label>
                        <select id="background-select" onChange={handleBackgroundChange}>
                            {backgroundColors.map((color) => (
                                <option key={color.value} value={color.value}>
                                    {color.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </nav>

            {/* 主banner */}
            <div className="main-banner">
                <img src="/images/banner.webp" alt="Main Banner" className="main-banner-image" />
            </div>

            {/* 主要內容區域 */}
            <div className="gallery-links">
                <div className="gallery-item">
                    <Link to="/bingbox" className="gallery-link">
                        <img src="/images/bing_banner.webp" alt="Bing Gallery" className="gallery-image" />
                        <h2 className="gallery-title">Bing AI Gallery</h2>
                    </Link>
                </div>
                <div className="gallery-item">
                    <Link to="/dallebox" className="gallery-link">
                        <img src="/images/dalle_banner.webp" alt="DALL-E Gallery" className="gallery-image" />
                        <h2 className="gallery-title">DALL-E AI Gallery</h2>
                    </Link>
                </div>
            </div>

            {/* 頁腳 (可選) */}
            <footer className="homepage-footer">
                <p>&copy; 2024 AI Image Galleries</p>
            </footer>
        </div>
    );
};

export default HomePage;
