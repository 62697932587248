export const topicsPerPage = 10;

// 定义Link接口
export interface Link {
    text: string;
    href: string;
}

export const menuLinks: Link[] = [
    { text: "Home", href: "/" },
    { text: "Bing Gallery", href: "/bingbox" },
    { text: "DALL-E Gallery", href: "/dallebox" },
]
