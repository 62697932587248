import React, { useState } from 'react';
// @ts-ignore
import Modal from 'react-modal';

import { DalleContentItem } from "../../data/dalle";

// 設置 Modal 根元素
Modal.setAppElement('#root');

const DalleContentDisplay: React.FC<{ items: DalleContentItem[] }> = ({ items }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [currentTitle, setCurrentTitle] = useState('');
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showInfo, setShowInfo] = useState(false); // 控制信息顯示狀態
    const [showWidgets, setShowWidgets] = useState(false); // 控制顯示狀態

    // 打開 Modal
    const openModal = (itemIndex: number, imageIndex: number, title: string) => {
        setCurrentItemIndex(itemIndex);
        setCurrentImageIndex(imageIndex);
        setCurrentImage(`/images/dalle/${items[itemIndex].images[imageIndex]}`);
        setCurrentTitle(title);
        setModalIsOpen(true);
        setShowInfo(false); // 確保打開時隱藏信息
    };

    // 關閉 Modal
    const closeModal = () => {
        setModalIsOpen(false);
    };

    // 切換到上一張圖片
    const goToPreviousImage = () => {
        let newImageIndex = currentImageIndex - 1;
        let newItemIndex = currentItemIndex;

        if (newImageIndex < 0) {
            newItemIndex = (currentItemIndex - 1 + items.length) % items.length;
            newImageIndex = items[newItemIndex].images.length - 1;
        }

        setCurrentItemIndex(newItemIndex);
        setCurrentImageIndex(newImageIndex);
        setCurrentImage(`/images/dalle/${items[newItemIndex].images[newImageIndex]}`);
        setCurrentTitle(items[newItemIndex].description);
        setShowInfo(false); // 切換圖片時隱藏信息
    };

    // 切換到下一張圖片
    const goToNextImage = () => {
        let newImageIndex = currentImageIndex + 1;
        let newItemIndex = currentItemIndex;

        if (newImageIndex >= items[newItemIndex].images.length) {
            newItemIndex = (currentItemIndex + 1) % items.length;
            newImageIndex = 0;
        }

        setCurrentItemIndex(newItemIndex);
        setCurrentImageIndex(newImageIndex);
        setCurrentImage(`/images/dalle/${items[newItemIndex].images[newImageIndex]}`);
        setCurrentTitle(items[newItemIndex].description);
        setShowInfo(false); // 切換圖片時隱藏信息
    };

    return (
        <>
            {items.map((item, itemIndex) => (
                <div key={itemIndex} className="content-item">
                    <p className="prompt-description">{item.description}</p>
                    <div className="images">
                        {item.images.map((image, imageIndex) => (
                            <a
                                key={imageIndex}
                                onClick={() => openModal(itemIndex, imageIndex, item.description)}
                                style={{ cursor: 'pointer' }}
                                className="image-link"
                            >
                                <img
                                    src={`/images/dalle/${image}`}
                                    alt={`/images/dalle/${image}`}
                                    title={`#${imageIndex + 1} of ${item.description}`}
                                    className="image-link-img"
                                />
                            </a>
                        ))}
                    </div>
                </div>
            ))}

            {/* 彈出視窗 */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Image Modal"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
            >
                {/* 圖片容器 */}
                <div
                    style={{ position: 'relative', textAlign: 'center' }}
                    onMouseEnter={() => setShowWidgets(true)}
                    onMouseLeave={() => setShowWidgets(false)}
                >
                    {/* 切換圖片按鈕 - 左箭頭 */}
                    {showWidgets && (
                        <button
                            onClick={goToPreviousImage}
                            style={{
                                position: 'absolute',
                                left: '0',
                                top: '50%',
                                height: '100%',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                                color: 'rgba(78,78,78,0.8)',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '5px', // 方形圓角效果
                            }}
                        >
                            <span className="material-symbols-outlined">
                                arrow_back_ios
                            </span>
                        </button>
                    )}

                    {/* 顯示圖片 */}
                    <img src={currentImage} alt="Current display" style={{ maxHeight: '80vh', maxWidth: '80vw' }} />

                    {/* 切換圖片按鈕 - 右箭頭 */}
                    {showWidgets && (
                        <button
                            onClick={goToNextImage}
                            style={{
                                position: 'absolute',
                                right: '0px',
                                top: '50%',
                                height: '100%',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                                color: 'rgba(78,78,78,0.8)',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '5px', // 方形圓角效果
                            }}
                        >
                            <span className="material-symbols-outlined">
                                arrow_forward_ios
                            </span>
                        </button>
                    )}

                    {/* "i" 圖示符號 */}
                    {!showInfo && showWidgets && (
                        <button
                            onClick={() => setShowInfo(true)}
                            style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', background: 'transparent', border: 'none', fontSize: '20px' }}
                        >
                            ℹ️
                        </button>
                    )}

                    {/* 圖片信息層 */}
                    {showInfo && (
                        <>
                            {/* 圖片標題 */}
                            <div style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                maxWidth: '100%', // 標題寬度與圖片寬度相同
                                padding: '10px',
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    right: '0',
                                    maxWidth: '100%', // 標題寬度與圖片寬度相同
                                    color: 'black',
                                    padding: '10px 40px 10px 10px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    textAlign: 'left',
                                    wordWrap: 'break-word' // 允許文字換行
                                }}>
                                    {currentTitle}
                                </div>
                            </div>

                            {/* 放大鏡區域 */}
                            <div style={{
                                position: 'absolute',
                                bottom: '10px',
                                right: '10px',
                                backgroundColor: 'white',
                                padding: '5px', // 增加放大鏡圖示周圍的白色背景
                                borderRadius: '5px', // 圓角效果
                            }}>
                                {/* 放大鏡圖示 */}
                                <a
                                    href={currentImage}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '20px',
                                        color: 'black',
                                        display: 'block',
                                        textDecoration: 'none',
                                        padding: '5px', // 放大鏡圖示的 padding
                                    }}
                                >
                                    🔍
                                </a>
                            </div>

                            {/* 關閉信息層按鈕 */}
                            <button
                                onClick={() => setShowInfo(false)}
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    cursor: 'pointer',
                                    background: 'transparent',
                                    border: 'none',
                                    fontSize: '20px'
                                }}
                            >
                                ❌
                            </button>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default DalleContentDisplay;
