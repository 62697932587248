import React, { useState, useEffect } from 'react';
import Loading from '../Loading'; // 引入Loading組件
import { useLogoTitle } from '../../utils/titleHelper';
import { useParams, useNavigate } from 'react-router-dom';

import BingContentDisplay from './BingContentDisplay'; // 引入内容显示组件
import Menu from '../Menu'; // 引入菜单组件
import Pagination from '../Pagination'; // 引入分页组件
import { menuLinks, topicsPerPage } from '../../data/data'; // 假设你的数据是从这里导入的
import { bingContentItems } from "../../data/bing";

const BingBox: React.FC = () => {
    const [loading, setLoading] = useState(true); // 初始設置 loading 為 true
    let { pageNumber = '1' } = useParams(); // 默认页码为1
    const navigate = useNavigate();

    const logoTitle = useLogoTitle(); // 使用共用的函数获取 logo 标题

    const [currentItems, setCurrentItems] = useState(bingContentItems.slice(0, topicsPerPage));
    const [safeCurrentPage, setSafeCurrentPage] = useState(parseInt(pageNumber, 10) || 1);

    const totalPages = Math.ceil(bingContentItems.length / topicsPerPage);

    // 頁面換頁時重新載入內容
    useEffect(() => {
        const indexOfLastItem = safeCurrentPage * topicsPerPage;
        const indexOfFirstItem = indexOfLastItem - topicsPerPage;

        // 設置 document.title
        document.title = `${logoTitle} - Page ${safeCurrentPage}`;

        // 顯示 loading 並重新設置內容
        setLoading(true);

        // 模擬內容載入過程
        setTimeout(() => {
            setCurrentItems(bingContentItems.slice(indexOfFirstItem, indexOfLastItem));
            setLoading(false); // 當內容完成後，隱藏 loading
        }, 500); // 調整為適當的載入時間
    }, [safeCurrentPage, logoTitle]);

    // 換頁時的處理函數
    const handlePageChange = (page: number) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSafeCurrentPage(page);
        // 更新 URL，以保證路由狀態正確
        navigate(`/bingbox/${page}`);
    };

    return (
        <>
            <Menu links={menuLinks} />
            <Pagination
                pathis="bingbox"
                totalPages={totalPages}
                currentPage={safeCurrentPage}
                onPageChange={handlePageChange}
            />
            {loading && <Loading />} {/* 當 loading 為 true 時顯示 Loading 組件 */}
            {!loading && <BingContentDisplay items={currentItems} />} {/* 當 loading 為 false 時顯示內容 */}
            <Pagination
                pathis="bingbox"
                totalPages={totalPages}
                currentPage={safeCurrentPage}
                onPageChange={handlePageChange}
            />
        </>
    );
};

export default BingBox;
