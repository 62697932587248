import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MenuLink } from '../data/data';
import { useLogoTitle } from '../utils/titleHelper'; // 引入共用的 useLogoTitle

interface MenuProps {
    links: MenuLink[];
}

const Menu: React.FC<MenuProps> = ({ links }) => {
    const logoTitle = useLogoTitle(); // 使用共用的函數獲取 logo 標題

    return (
        <header className="menu-header">
            {/* 動態 Logo 標題 */}
            <h1 className="logo-title">{logoTitle}</h1>

            {/* Sub-links 導覽列 */}
            <nav className="sub-navigation">
                <ul className="sub-menu">
                    {/* Home 僅在精確路徑為 'AI Gallery' 時禁用 */}
                    <li>
                        {logoTitle === 'AI Gallery' ? (
                            <span className="disabled-link">Home</span>
                        ) : (
                            <Link to="/">Home</Link>
                        )}
                    </li>
                    {/* Bing Gallery 僅在精確路徑為 'AI Gallery - Bing' 時禁用 */}
                    <li>
                        {logoTitle === 'AI Gallery - Bing' ? (
                            <span className="disabled-link">Bing Gallery</span>
                        ) : (
                            <Link to="/bingbox">Bing Gallery</Link>
                        )}
                    </li>
                    {/* DALL-E Gallery 僅在精確路徑為 'AI Gallery - Dall-E' 時禁用 */}
                    <li>
                        {logoTitle === 'AI Gallery - Dall-E' ? (
                            <span className="disabled-link">DALL-E Gallery</span>
                        ) : (
                            <Link to="/dallebox">DALL-E Gallery</Link>
                        )}
                    </li>
                </ul>
            </nav>

            {/* 其他導覽列 */}
            {/*<nav className="head-menu">*/}
            {/*    <ul className="top-menu">*/}
            {/*        {links.map((link, index) => (*/}
            {/*            <li key={index} className={`top-menu-item ${index === 0 ? 'left' : ''} ${links.length === index + 1 ? 'right' : ''}`}>*/}
            {/*                <Link to={link.href}>{link.text}</Link>*/}
            {/*            </li>*/}
            {/*        ))}*/}
            {/*    </ul>*/}
            {/*</nav>*/}
        </header>
    );
};

export default Menu;
