import { useLocation } from 'react-router-dom';

// 共用的函數：根據路徑決定 logo 的附加標題
export const useLogoTitle = (): string => {
    const location = useLocation();

    if (location.pathname.startsWith('/bingbox')) {
        return 'AI Gallery - Bing';
    }
    if (location.pathname.startsWith('/dallebox')) {
        return 'AI Gallery - Dall-E';
    }
    return 'AI Gallery';
};
