import React from 'react';
import { useNavigate } from 'react-router-dom';

interface PaginationProps {
    pathis: string;
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void; // 添加 onPageChange 回調
}

const Pagination: React.FC<PaginationProps> = ({ pathis, totalPages, currentPage, onPageChange }) => {
    let navigate = useNavigate();

    const goToPage = (pageNumber: number) => {
        // 導航到指定頁面
        navigate(`/${pathis}/${pageNumber}`);

        // 透過 onPageChange 通知父組件換頁
        onPageChange(pageNumber);

        // 將頁面移動到頂部
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <hr />
            <nav className="pages">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button onClick={() => goToPage(1)} disabled={currentPage === 1}>
                            &lt;&lt;
                        </button>
                    </li>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
                            &lt;
                        </button>
                    </li>

                    {/* 渲染頁碼 */}
                    {[...Array(totalPages)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                            <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                                <button onClick={() => goToPage(pageNumber)}>
                                    {pageNumber}
                                </button>
                            </li>
                        );
                    })}

                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
                            &gt;
                        </button>
                    </li>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>
                            &gt;&gt;
                        </button>
                    </li>
                </ul>
            </nav>
            <hr />
        </>
    );
};

export default Pagination;
